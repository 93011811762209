import React from 'react'

import hat from '../../images/light-black-and-white-man-person-67603-cropped.jpg'

const About = () => {
    return (
        <div className="about-page">
            <p>Another in a long line of alter egos...</p>
            <p>
                <img className="hat-img" src={hat} alt="white top-hat tilted" />
                In the unending quest to fulfill the need to create I bring you brainstepchild.<br />
                These are the things that keep me up at night; the things I want to have experienced by the unnamed masses.
                These are the children of my mind that I do not whole-heartedly endorse when criticised. However, when praised I beam like a proud parent.
                So peruse if you don't mind and criticize to your heart's content. Realize, dear peruser, that I am ready to disavow my brain-step-chilren as mere flights of fancy at the drop or a hat.
            </p>
        </div>
    )
}

export default About