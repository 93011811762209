import React from 'react'
import Container from 'react-bootstrap/Container'
import { Switch, Route } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css';

import MainNav from './MainNav'
import Home from './pages/Home'
import About from './pages/About'
import NotFound from './pages/NotFound'

import '../scss/site.scss'

const App = () => {
    return (
        <div className="backdrop">
            <Container className="app">
                <MainNav />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/" component={NotFound} />
                </Switch>
            </Container>
        </div>
    )
}

export default App